.introTextDiv {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
  text-align: center;
  top: 4%;
  /* margin-left: 8%; */
}

.textOne {
  font-family: "Dosis";
  font-size: 2.5rem;
  margin-bottom: 0.2rem;
  color: #2e2e2e;
}

.textTwo {
  font-family: "LittleMissLoudonBold";
  font-size: 2.5rem;
  color: #2e2e2e;
  margin-top: 0.1rem;
  margin-bottom: 0.5rem;
}

.introButtonDiv {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20%;
}

.introButton {
  font-family: "Dosis";
  font-size: 2rem;
  background-color: transparent;
  padding: 0.5rem 1rem;
  border: none;
}

.introButton:hover {
  cursor: pointer;
  color: rgb(143, 143, 143);
  background-color: rgba(0, 0, 0, 0.8);
}

.animationCredit {
  font-size: 10px;
  color: rgb(111, 111, 111);
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0.8;
}

@media only screen and (min-width: 767px) {
  .introTextDiv {
    position: absolute;
    left: auto;
    width: auto;
    transform: none;
    text-align: start;
    top: 8%;
    margin-left: 8%;
  }
  .textOne {
    margin-bottom: 0.5rem;
    font-size: 4rem;
  }
  .textTwo {
    margin-top: 0.5rem;
    font-size: 4rem;
  }
  .introButtonDiv {
    position: absolute;
    bottom: 10%;
    left: auto;
    right: 8%;
  }
  .introButton {
    font-size: 3rem;
  }
}
