.sidebar-fix:not(body) {
  transform: none;
}
.ui.sidebar {
  position: sticky;
  top: 0;
  height: 100vh !important;
}

.pushable:not(body) > .ui.sidebar {
  position: fixed;
  top: 0;
}

i.bordered.icon {
  line-height: 1;
  vertical-align: baseline;
  padding: 0.6em 0 !important;
  box-shadow: 0 0 0 0.1em rgb(45, 217, 255) inset;
}

.ui.vertical.menu .item > i.icon {
  margin: 0;
}
