@font-face {
  font-family: "LittleMissLoudonBold";
  src: local("LittleMissLoudonBold"),
    url(fonts/LittleMissLoudonBold.ttf) format("truetype");
}

@font-face {
  font-family: "Dosis";
  src: local("Dosis"), url(fonts/Dosis.ttf) format("truetype");
}

@font-face {
  font-family: "Lobster";
  src: local("Lobster"), url(fonts/Lobster.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#wrapper {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
}

#root {
  position: absolute;
  top: 0;
  width: 100%;
}

#renderer {
  width: 100%;
  height: 100%;
}

#renderer-wrapper {
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 128, 214);
  background: linear-gradient(
    12deg,
    rgb(115, 174, 214) 20%,
    rgba(220, 169, 59, 1) 80%
  );
  overflow: hidden;
  transition: opacity 1s ease-out;
}

#renderer-wrapper.fade {
  opacity: 0;
}
